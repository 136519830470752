import "./UlSections.css";
import { useState } from "react";
import stylesWithOutImg from "../../utils/stylesWithOutImg";

const Quality = () => {
  const [imgloaded, setImgloaded] = useState(false);
  return (
    <>
      <div className="content-on-imgSection">
        <section name="Як зросте якість продукції після придбання робота ?">
          <div className="img-under-content toRight">
            <img
              src={require("../../STATIC/images/QualatitiveRobots/SettingsRobot.png")}
              alt="Robot"
              onLoad={() => {
                setImgloaded(true);
              }}
            />
          </div>
          <div
            className="content-on-img toLeft-content-on-img"
            style={stylesWithOutImg(imgloaded)}
          >
            <hr />
            <h2>
              Як зросте якість продукції
              <br /> після придбання робота ?
            </h2>
            <ul>
              <li>
                <span className="weight-text">Постійність якості:</span> Завдяки
                високій точності виконання операцій роботи забезпечують
                стабільну якість продукції на всіх етапах виробництва.
              </li>
              <li>
                <span className="weight-text">Мінімізація дефектів:</span>{" "}
                Автоматизовані процеси знижують ймовірність помилок, що зменшує
                кількість дефектної продукції.
              </li>
              <li>
                <span className="weight-text">
                  Підвищення конкурентоспроможності:
                </span>{" "}
                Висока якість продукції дозволяє вам конкурувати на глобальному
                ринку, задовольняючи вимоги найвибагливіших клієнтів.
              </li>
              <li>
                <span className="weight-text">
                  Стабільність виробничих процесів:
                </span>{" "}
                Роботи ABB здатні працювати в жорстких умовах, зберігаючи високу
                продуктивність та якість.
              </li>
            </ul>
          </div>
        </section>
      </div>
    </>
  );
};

export default Quality;
