const defineRefs = (refType, refs) => {
  switch (refType) {
    case "anchorRef":
      return refs?.anchorRef;
    case "aboutUsRef":
      return refs?.aboutUsRef;
    case "contactsRef":
      return refs?.contactsRef;
    case "pricesAnchorRef":
      return refs?.contactsRef;
    default:
      return null;
  }
};

export default defineRefs;
