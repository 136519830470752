import "./Main.css";
import ABBIrb2400L from "./CardSections/ABBIrb2400L";
import ABBIrb1400 from "./CardSections/ABBIrb1400";

const Main = () => {
  return (
    <div className="pricesMain">
      <ABBIrb1400></ABBIrb1400>
      <ABBIrb2400L></ABBIrb2400L>
    </div>
  );
};

export default Main;
