import "./Main.css";
import Advantages from "../UlSections/Advantages";
import Quality from "../UlSections/Quality";
import Efficiency from "../UlSections/Efficiency";
import Profitability from "../UlSections/Profitability";
import GuaranteedSystem from "../UlSections/GuaranteedSystem";
import AboutUs from "../UlSections/AboutUs";
import BlokLinkToPrices from "../UlSections/BlokLinkToPrices";

const Main = () => {
  return (
    <div>
      <BlokLinkToPrices></BlokLinkToPrices>
      <Efficiency></Efficiency>
      <Profitability></Profitability>
      <Quality></Quality>
      <Advantages></Advantages>

      <div className="bloks-section">
        <GuaranteedSystem></GuaranteedSystem>
        <AboutUs></AboutUs>
      </div>
    </div>
  );
};

export default Main;
