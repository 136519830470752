import "./UlSections.css";
import { useState } from "react";
import stylesWithOutImg from "../../utils/stylesWithOutImg";

const Profitability = () => {
  const [imgloaded, setImgloaded] = useState(false);
  return (
    <>
      <div className="content-on-imgSection">
        <section name="Вигідність придбання робота">
          <div className="img-under-content">
            <img
              src={require("../../STATIC/images/QualatitiveRobots/TwoHandRobot.png")}
              alt="Robot"
              onLoad={() => {
                setImgloaded(true);
              }}
            />
          </div>
          <div
            className="content-on-img  toRight-content-on-img"
            style={stylesWithOutImg(imgloaded)}
          >
            <hr />
            <h2>Вигідність придбання робота</h2>
            <ul>
              <li>
                <span className="weight-text">
                  Швидке повернення інвестицій:
                </span>{" "}
                Завдяки підвищенню продуктивності та зниженню витрат, наші
                роботи окупаються в найкоротші терміни. Саме така система
                окупається за 6-8 місяців!
              </li>
              <li>
                <span className="weight-text">Заміна людської праці:</span>{" "}
                Робот може замінити 6 людей на виробництві, що значно зменшить
                витрати на заробітну плату.
              </li>
              <li>
                <span className="weight-text">Відновлені роботи:</span> У нас є
                на продаж не тільки нові роботи, а й відновлені роботи. Ми
                проводимо повне обслуговування техніки, яке придбаємо в Європі,
                замінюємо всі потрібні компоненти на нові та повністю
                перебираємо робот і блок керування, щоб забезпечити надійність
                техніки. Ми даємо гарантію на все обладнання, яке продаємо.
              </li>
              <li>
                <span className="weight-text">Комплектація систем:</span> Ми
                можемо доукомплектувати систему будь-яким іншим обладнанням, яке
                потрібно замовнику, що забезпечує індивідуальний підхід та
                максимальну відповідність потребам вашого виробництва.
              </li>
            </ul>
          </div>
        </section>
      </div>
    </>
  );
};

export default Profitability;
