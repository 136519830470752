import "./NavBar.css";
import { useState } from "react";
import { useContext } from "react";
import { RefsContext } from "../RefsProvider";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const NavBar = () => {
  const { anchorRef, contactsRef, aboutUsRef, pricesAnchorRef } =
    useContext(RefsContext);
  const [menuActive, setMenuActive] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleMenu = (logo = false) => {
    if (logo) {
      setMenuActive(false);
    } else {
      setMenuActive(!menuActive);
    }
  };

  function smoothScroll(ref, logo = false) {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    toggleMenu(logo);
  }
  function handleNavBarClick(ref, refType, logo = false) {
    if (location.pathname !== "/" && refType !== "contactsRef") {
      navigate(".", { state: { scrollRef: refType } });
      setMenuActive(false);
    } else {
      smoothScroll(ref, logo);
    }
  }

  return (
    <nav>
      <div id="main-navbar" className="navbar-height">
        <div className="navbar">
          <div className="Logo">
            <h1 onClick={() => handleNavBarClick(anchorRef, "anchorRef", true)}>
              RWS
            </h1>
          </div>
          <ul
            className={`navbar-items ${
              menuActive ? "navbar-items-active" : ""
            }`}
          >
            <li onClick={() => handleNavBarClick(anchorRef, "anchorRef")}>
              Головна
            </li>
            <li onClick={() => handleNavBarClick(aboutUsRef, "aboutUsRef")}>
              Про нас
            </li>
            <li onClick={() => handleNavBarClick(contactsRef, "contactsRef")}>
              Контакти
            </li>
            <li
              onClick={() => {
                smoothScroll(pricesAnchorRef);
                if (location.pathname !== "/prices") {
                  navigate("prices");
                }
              }}
            >
              Ціни
            </li>
          </ul>
          <div
            className={`burger ${menuActive ? "burger-active" : ""}`}
            onClick={() => {
              toggleMenu();
            }}
          >
            <div
              className={`burger-lines ${
                menuActive ? "burger-lines-active" : ""
              }`}
            ></div>
            <div
              className={`burger-lines ${
                menuActive ? "burger-lines-active" : ""
              }`}
            ></div>
            <div
              className={`burger-lines ${
                menuActive ? "burger-lines-active" : ""
              }`}
            ></div>
          </div>
        </div>
      </div>
      <div className="navbar-height"></div>
    </nav>
  );
};

export default NavBar;
