import "./Footer.css";
import { useContext } from "react";
import { RefsContext } from "../RefsProvider";

const Footer = () => {
  const { contactsRef } = useContext(RefsContext);
  return (
    <div className="footer" ref={contactsRef}>
      <section name="Контакти">
        <h2>Наші Контакти</h2>
        <div className="footer-info">
          <p>
            Зверніться до нас сьогодні, щоб дізнатися більше про те, як роботи
            ABB можуть змінити ваше виробництво. Наші спеціалісти готові надати
            вам всю необхідну інформацію та допомогти зробити правильний вибір.
            Автоматизуйте своє виробництво з ABB.
          </p>
        </div>
        <div className="footer-contacts">
          <div>
            <p>Соляніков Павло</p>
            <p>+ 38(050) 812 19 26</p>
            <p>pashasolyanikov @gmail.com</p>
          </div>
          <div>
            <p>Соляніков Олександр</p>
            <p>+ 38(096) 69 100 94</p>
            <p>shurahin @gmail.com</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
