import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useContext } from "react";
import { RefsContext } from "../RefsProvider";
import Header from "./Home/Header";
import Main from "./Home/Main";
import defineRefs from "../utils/defineRefs";

const Home = () => {
  const location = useLocation();
  const { anchorRef, contactsRef, aboutUsRef } = useContext(RefsContext);
  // location.state?.scrollToTarget
  useEffect(() => {
    const scrollToRef = defineRefs(location.state?.scrollRef, {
      anchorRef,
      contactsRef,
      aboutUsRef,
    });
    if (scrollToRef) {
      setTimeout(() => {
        scrollToRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 200);
      window.history.replaceState(
        { ...location.state, scrollRef: "anchorRef" },
        ""
      );
    }
  }, [location, anchorRef, contactsRef, aboutUsRef]);
  return (
    <>
      <header>
        <Header></Header>
      </header>
      <main>
        <Main></Main>
      </main>
    </>
  );
};

export default Home;
