import "./Header.css";
import { useContext } from "react";
import { RefsContext } from "../../RefsProvider";

const Header = () => {
  const { pricesAnchorRef } = useContext(RefsContext);

  return (
    <div className="pricesHeader" ref={pricesAnchorRef}>
      <h1>Ціни</h1>
    </div>
  );
};

export default Header;
