import { useNavigate } from "react-router-dom";

const BlokLinkToPrices = () => {
  const navigate = useNavigate();
  return (
    <div className="blok-link-toPrices">
      <h2>Ціни наших роботів</h2>
      <button
        onClick={() => {
          navigate("prices");
        }}
      >
        тут
      </button>
    </div>
  );
};

export default BlokLinkToPrices;
