import "./CardSections.css";
import { useState, useEffect } from "react";

const ABBIrb2400L = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
  }, []);
  const textOnCard = (
    <div className="text-in-card">
      <h3>Що входить в систему:</h3>
      <hr />
      <ul>
        <li>1) Робот-маніпулятор ABB irb 2400L</li>
        <li>2) Позиціонер (обертач), синхронізований з роботом</li>
        <li>
          3) Зварювальний апарат DALEX VARIO MIG 400 (100% навантаження 315A) +
          Роботизована подача проволоки
        </li>
        <li>4) Роботизований рукав з рідинною системою охолодження</li>
        <li>5) Навчання оператора / персоналу на роботі ( 1 людина ).</li>
      </ul>
      <p className="weight-text">Ціна системи: 27 000 євро.</p>
    </div>
  );
  return (
    <div className="card-blok">
      <h2>ABB irb 2400L</h2>
      <div className="card">
        <div className="card-img">
          <img
            src={require("../../../STATIC/images/OrangeRobots/OrangeRobotAndStand.jpg")}
            alt="Robot"
          />
        </div>
        <div className="card-video-text">
          <iframe
            src="https://www.youtube.com/embed/z4aTon51Lss"
            title="RWS - Роботизований зварювальний комплекс ABB"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
          {windowWidth > 900 ? textOnCard : ""}
        </div>
        {windowWidth <= 900 ? textOnCard : ""}
      </div>
    </div>
  );
};

export default ABBIrb2400L;
