import "./UlSections.css";
import { useState } from "react";
import stylesWithOutImg from "../../utils/stylesWithOutImg";

const GuaranteedSystem = () => {
  const [imgloaded, setImgloaded] = useState(false);
  return (
    <>
      <div className="text-img-blok" style={stylesWithOutImg(imgloaded)}>
        <section name="Гарантовані переваги нашої системи">
          <div className="">
            <img
              src={require("../../STATIC/images/QualatitiveRobots/LightRobot.png")}
              alt="Robot"
              onLoad={() => {
                setImgloaded(true);
              }}
            />
          </div>
          <div className="textOn-blokImgText">
            <hr />
            <h2>Гарантовані переваги нашої системи</h2>
            <ul>
              <li>
                <span className="weight-text">Ідеальний стан:</span> Наш робот
                пройшов повне технічне обслуговування і готовий до максимального
                навантаження. Ми надаємо гарантію на всю систему.
              </li>
              <li>
                <span className="weight-text">Гнучкість та адаптивність:</span>{" "}
                Ми можемо обладнати нашу систему штучним зором, що дозволяє
                коригувати координати зварювання та забезпечувати високу
                точність навіть в умовах, коли кондуктор не є ідеальним або
                деталі відрізняються. Це наша конкурентна перевага, яку небагато
                інтеграторів можуть запропонувати.
              </li>
              <li>
                <span className="weight-text">Повна технічна підтримка:</span>{" "}
                Наша команда готова не тільки інсталювати систему, але й
                підключити всі необхідне обладнання під ключ. Ми забезпечуємо
                технічну підтримку та обслуговування системи протягом всього
                строку експлуатації.
              </li>
            </ul>
          </div>
        </section>
      </div>
    </>
  );
};

export default GuaranteedSystem;
