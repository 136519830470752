import "./UlSections.css";
import { useState } from "react";
import stylesWithOutImg from "../../utils/stylesWithOutImg";

const Advantages = () => {
  const [imgloaded, setImgloaded] = useState(false);
  return (
    <>
      <div className="content-on-imgSection">
        <section name="Переваги промислового робота">
          <div className="img-under-content">
            <img
              src={require("../../STATIC/images/QualatitiveRobots/UpwardRobot.png")}
              alt="Robot"
              onLoad={() => {
                setImgloaded(true);
              }}
            />
          </div>
          <div
            className="content-on-img toRight-content-on-img"
            style={stylesWithOutImg(imgloaded)}
          >
            <hr />
            <h2>Переваги промислового робота</h2>
            <ul>
              <li>
                <span className="weight-text">
                  Висока точність і стабільність:
                </span>{" "}
                Роботи ABB здатні виконувати завдання з мікрометровою точністю,
                що забезпечує постійну якість продукції.
              </li>
              <li>
                <span className="weight-text">Підвищена продуктивність:</span>{" "}
                Швидкі та ефективні роботи можуть працювати 24/7 без втоми,
                збільшуючи обсяги виробництва.
              </li>
              <li>
                <span className="weight-text">Гнучкість у виробництві:</span>{" "}
                Наші роботи легко адаптуються до змін у виробничому процесі,
                дозволяючи швидко впроваджувати нові продукти.
              </li>
              <li>
                <span className="weight-text">Зменшення витрат:</span>{" "}
                Автоматизація процесів дозволяє знизити витрати на робочу силу
                та мінімізувати втрати через людські помилки.
              </li>
              <li>
                <span className="weight-text">Інтеграція штучного зору:</span>{" "}
                Ми пропонуємо можливість інтеграції штучного зору до
                роботизованого комплексу, що дозволяє підвищити ефективність та
                точність виконання завдань.
              </li>
            </ul>
          </div>
        </section>
      </div>
    </>
  );
};

export default Advantages;
