import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Prices from "./components/Prices";
import NotFound from "./components/NotFound";
import MainLayout from "./layouts/MainLayout";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<MainLayout></MainLayout>}>
            <Route index element={<Home></Home>} />
            <Route path="prices" element={<Prices></Prices>} />
            <Route path="*" element={<NotFound></NotFound>} />
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
