import NavBar from "./NavBar";

const NotFound = () => {
  return (
    <>
      <NavBar></NavBar>
      <br />
      <br />
      <h1>Page not found</h1>
    </>
  );
};

export default NotFound;
