import "./Header.css";
import { useState } from "react";
import { useContext } from "react";
import { RefsContext } from "../../RefsProvider";

const Header = () => {
  const { anchorRef } = useContext(RefsContext);
  const [imgloaded, setImgloaded] = useState(false);
  return (
    <div className="Header" ref={anchorRef}>
      <img
        src={require("../../STATIC/images/header/white_robot_1.6_cut.png")}
        alt="Robots"
        onLoad={() => setImgloaded(true)}
      />
      <div
        className="elementOnImage"
        style={!imgloaded ? { position: "static" } : {}}
      >
        <h1>
          Інноваційні промислові
          <br /> роботи від ABB
        </h1>
      </div>
    </div>
  );
};

export default Header;
