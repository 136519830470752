import "./UlSections.css";
import { useState } from "react";
import stylesWithOutImg from "../../utils/stylesWithOutImg";

const Efficiency = () => {
  const [imgloaded, setImgloaded] = useState(false);
  return (
    <>
      <div className="content-on-imgSection">
        <section name="Ефективність та прибутковість для клієнтів">
          <div className="img-under-content toRight">
            <img
              src={require("../../STATIC/images/QualatitiveRobots/InDarkRobot.png")}
              alt="Robot"
              onLoad={() => {
                setImgloaded(true);
              }}
            />
          </div>
          <div
            className="content-on-img toLeft-content-on-img"
            style={{ ...stylesWithOutImg(imgloaded) }}
          >
            <hr />
            <h2>
              Ефективність та прибутковість
              <br /> для клієнтів
            </h2>
            <ul>
              <li>
                <span className="weight-text">Комплексні рішення:</span> Ми
                продаємо робот "під ключ", забезпечуючи всі етапи впровадження,
                від вибору моделі до запуску виробництва.
              </li>
              <li>
                <span className="weight-text">Навчання персоналу:</span> Ми
                займаємося навчанням персоналу наших клієнтів керування роботом
                та написання складних програм, що дозволяє максимально
                використовувати можливості наших роботів.
              </li>
              <li>
                <span className="weight-text">Різноманітність моделей:</span> У
                нас є в наявності різні моделі роботів, що дозволяє підібрати
                оптимальне рішення для будь-яких виробничих завдань.
              </li>
              <li>
                <span className="weight-text">Професійний сервіс:</span> Ми
                професійно займаємося сервісом та обслуговуванням промислових
                роботів в Україні, що забезпечує їх безперебійну роботу та
                тривалий термін служби.
              </li>
              <li>
                <span className="weight-text">Інтеграція штучного зору:</span>{" "}
                Наші рішення включають можливість інтеграції штучного зору до
                роботизованих комплексів, що додатково підвищує ефективність та
                точність виробничих процесів.
              </li>
            </ul>
          </div>
        </section>
      </div>
    </>
  );
};

export default Efficiency;
