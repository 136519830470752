import "./Prices.css";
import Main from "./Prices/Main";
import Header from "./Prices/Header";
import { useContext } from "react";
import { RefsContext } from "../RefsProvider";
import { useEffect } from "react";

const Prices = () => {
  const { pricesAnchorRef } = useContext(RefsContext);

  useEffect(() => {
    setTimeout(() => {
      pricesAnchorRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 200);
  });

  return (
    <div className="Prices">
      <header>
        <Header></Header>
      </header>
      <main>
        <Main></Main>
      </main>
    </div>
  );
};

export default Prices;
