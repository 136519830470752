import "./UlSections.css";
import { useState } from "react";
import stylesWithOutImg from "../../utils/stylesWithOutImg";
import { useContext } from "react";
import { RefsContext } from "../../RefsProvider";

const AboutUs = () => {
  const { aboutUsRef } = useContext(RefsContext);
  const [imgloaded, setImgloaded] = useState(false);
  return (
    <div
      className="text-img-blok"
      style={stylesWithOutImg(imgloaded)}
      ref={aboutUsRef}
    >
      <section name="Про нас">
        <div className="">
          <img
            src={require("../../STATIC/images/QualatitiveRobots/PrinterRobot.png")}
            alt="Robot"
            onLoad={() => {
              setImgloaded(true);
            }}
          />
        </div>
        <div className="textOn-blokImgText">
          <hr />
          <h2>Про нас</h2>
          <ul>
            <li>
              <span className="weight-text">Досвід та надійність:</span> Наша
              компанія на ринку вже понад 6 років, і ми не тільки пропонуємо
              систему, але і маємо великий досвід в ремонті та обслуговуванні
              систем роботів ABB. Наші системи вже вправно працюють у Харкові,
              Запоріжжі, Дніпрі, Львові, Івано-Франківську, Києві! У нас є
              широкий запас комплектуючих та деталей для роботів ABB, що
              гарантує надійність та швидкі рішення при обслуговуванні.
            </li>
            <li>
              <span className="weight-text">Термін постачання:</span> Ми
              гарантуємо постачу системи протягом 8-12 тижнів, що дозволить вам
              швидко почати використовувати робота для збільшення продуктивності
            </li>
            <li>
              <span className="weight-text">Ми впевнені</span>, що наша система
              є оптимальним рішенням для вашого виробництва та забезпечить
              збільшення продуктивності та якості зварювальних робіт. Ми готові
              відповісти на всі ваші питання, продемонструвати роботу системи та
              обговорити, як вона може оптимізувати ваше виробництво. З нами
              ваше виробництво буде працювати більш ефективно, надійно і
              безпечно.
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
