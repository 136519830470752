import RefsContext from "./context/RefsContext";
import { useRef } from "react";

const RefsProvider = ({ children }) => {
  const anchorRef = useRef(null);
  const aboutUsRef = useRef(null);
  const contactsRef = useRef(null);

  const pricesAnchorRef = useRef(null);
  const refs = { anchorRef, aboutUsRef, contactsRef, pricesAnchorRef };

  return <RefsContext.Provider value={refs}>{children}</RefsContext.Provider>;
};

export { RefsContext, RefsProvider };
