const stylesWithOutImg = (imageLoaded) => {
  return imageLoaded
    ? {}
    : {
        position: "static",
        margin: "0px auto",
        transform: "none",
        left: "none",
        right: "none",
      };
};

// backgroundColor: "pink",

export default stylesWithOutImg;
